import { List, ListItem, Text, useDisclosure } from "@chakra-ui/react";
import * as Turf from "@turf/helpers";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { InfoAlert } from "src/components/Feedback/InfoAlert";
import { Glossary } from "src/components/Text/Glossary";
import { useLoadGooglePlacesScript } from "src/hooks/useLoadGoogleMaps";
import {
  findFormAddressAnswer,
  findGradeAnswer,
  FormikValues,
} from "src/services/formTemplate/answer";
import {
  calculateSchoolsToRemove,
  RankedSchool,
} from "src/services/formTemplate/preRankingSection";
import * as AF from "src/types/formTemplate";
import * as RD from "src/types/remoteData";
import { addressLookup } from "../Boundary/services";
import { useBoundariesMap } from "../Boundary/useBoundary";
import { RemoteDataView } from "../Layout/RemoteDataView";

type Props = {
  formId: uuid;
  rankedSchools: readonly RankedSchool[];
  completeQuestions: readonly AF.Question<AF.WithId>[];
  answers: FormikValues;
  rankingEnabled: boolean;
};

export const RankedSchoolsRemovalAlert: React.FC<Props> = ({
  formId,
  rankedSchools,
  completeQuestions,
  answers,
  rankingEnabled,
}) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const boundariesMap = useBoundariesMap({ formId });
  const [location, setLocation] = useState<
    RD.RemoteData<unknown, Turf.Position | undefined>
  >(RD.notAsked());

  useLoadGooglePlacesScript();

  const gradeConfigId = useMemo(() => {
    return findGradeAnswer(completeQuestions, answers);
  }, [answers, completeQuestions]);

  const initialRankedSchoolsToRemove = useMemo((): RankedSchool[] => {
    if (location.hasData() && boundariesMap.hasData()) {
      return calculateSchoolsToRemove(
        rankedSchools,
        completeQuestions,
        answers,
        gradeConfigId?.gradeConfigId,
        location.data,
        boundariesMap.data
      );
    }

    return [];
  }, [
    answers,
    boundariesMap,
    completeQuestions,
    gradeConfigId,
    location,
    rankedSchools,
  ]);

  const [rankedSchoolsToRemove, refreshRankedSchoolsToRemove] = useState(
    RD.success(initialRankedSchoolsToRemove)
  );

  useEffect(() => {
    if (!window.google?.maps) {
      return;
    }

    setLocation(RD.loading());
    const address = findFormAddressAnswer(completeQuestions, answers);
    if (address === undefined) {
      setLocation(RD.success(undefined));
      return;
    }

    addressLookup(new google.maps.Geocoder(), address)
      .then((location) => {
        setLocation(RD.success(location));
      })
      .catch((e) => setLocation(RD.failure(e)));
  }, [answers, completeQuestions]);

  useEffect(() => {
    RD.toTuple(location, boundariesMap).do(([location, boundariesMap]) => {
      refreshRankedSchoolsToRemove(
        RD.success(
          calculateSchoolsToRemove(
            rankedSchools,
            completeQuestions,
            answers,
            gradeConfigId?.gradeConfigId,
            location,
            boundariesMap
          )
        )
      );
    });
  }, [
    location,
    gradeConfigId,
    boundariesMap,
    rankedSchools,
    completeQuestions,
    answers,
  ]);

  return (
    <RemoteDataView error={() => null} remoteData={rankedSchoolsToRemove}>
      {(rankedSchoolsToRemove) => {
        if (!isOpen || rankedSchoolsToRemove.length <= 0) return null;
        return (
          <InfoAlert closeAlert={onClose}>
            <Text>
              <Glossary>
                By saving changes, these schools will be removed from the school
                choices:
              </Glossary>
            </Text>
            <List listStyleType={rankingEnabled ? "none" : "disc"}>
              {_.sortBy(
                rankedSchoolsToRemove,
                (rankedSchool) => rankedSchool.rank
              ).map((rankedSchool) => {
                return (
                  <ListItem ml={rankingEnabled ? 0 : 4}>
                    <Text key={rankedSchool.school.id}>
                      {rankingEnabled && `${rankedSchool.rank + 1}. `}
                      {rankedSchool.school.name}
                    </Text>
                  </ListItem>
                );
              })}
            </List>
          </InfoAlert>
        );
      }}
    </RemoteDataView>
  );
};
