import { Flex, Grid, ListItem } from "@chakra-ui/react";
import * as GQL from "src/types/graphql";
import { FormSchoolRankDetail } from "./FormSchoolRankDetail";

interface FormSchoolRankProps {
  formTemplateId: uuid;
  acceptedOfferSchoolName: string | undefined;
  form: Pick<GQL.FormSummaryFragment, "id" | "status">;

  offer: Pick<
    GQL.FormSummaryFragment_form_school_ranks_offers,
    "id" | "status"
  > | null;

  formSchoolRank: Pick<
    GQL.FormSummaryFragment_form_school_ranks,
    "rank" | "sub_status" | "tags"
  >;

  waitlist: Pick<
    GQL.FormSummaryFragment_form_school_ranks_waitlists,
    "waitlist_position" | "status"
  > | null;

  gradeLabel: string;
  school: Pick<GQL.SchoolFragment, "id" | "name">;
  studentName: string;
  rankingEnabled: boolean;
}

export function FormSchoolRank(props: FormSchoolRankProps) {
  const { formSchoolRank, rankingEnabled } = props;

  // ListItem cannot have `display="flex"` or bullets won't show up
  return (
    <ListItem ml={rankingEnabled ? 0 : 5}>
      <Flex alignItems="center" gap={2}>
        {rankingEnabled && (
          <Grid
            alignSelf="stretch"
            borderInlineEndColor="gray.400"
            borderInlineEndStyle="solid"
            borderInlineEndWidth="1px"
            flexBasis={6}
            flexGrow={0}
            flexShrink={0}
            placeContent="center"
          >
            {formSchoolRank.rank + 1}
          </Grid>
        )}
        <FormSchoolRankDetail {...props} />
      </Flex>
    </ListItem>
  );
}
