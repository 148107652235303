import { gql } from "@apollo/client";
import { FORM_SUMMARY_FRAGMENT } from "src/operations/fragments/form";
import {
  ENROLLMENT_PERIOD_BASICS_FRAGMENT,
  FORM_STATUS_DESCRIPTION,
} from "src/operations/fragments/formTemplate";

export const GET_PARENT_ASSIGNED_FORMS = gql`
  query GetParentAssignedForms($organization_id: uuid!) {
    assigned_form(
      where: {
        has_started: { _eq: false }
        deleted_at: { _is_null: true }
        form_template: {
          deleted_at: { _is_null: true }
          active: { _eq: true }
          enrollment_period: {
            deleted_at: { _is_null: true }
            organization_id: { _eq: $organization_id }
          }
        }
      }
    ) {
      id
      form_template {
        id
        name
      }
      person_id
      previous_form_id
      previous_offer_id
      previous_waitlist_id
    }
  }
`;

export const GET_PARENT_FORMS_INDEX = gql`
  query GetParentFormsIndex($organization_id: uuid!, $guardian_id: uuid!) {
    assigned_form(
      where: {
        has_started: { _eq: false }
        deleted_at: { _is_null: true }
        form_template: {
          active: { _eq: true }
          enrollment_period: { organization_id: { _eq: $organization_id } }
        }
      }
    ) {
      id
      form_template {
        id
        name
      }
      person_id
      previous_form_id
      previous_offer_id
      previous_waitlist_id
    }

    person(
      order_by: [{ last_name: asc }, { first_name: asc }, { birth_date: asc }]

      where: {
        organization_id: { _eq: $organization_id }
        person_type: { _eq: applicant }
        active: { _eq: true }
        _or: [
          {
            second_relationship: {
              _and: {
                active: { _eq: true }
                first_person: { _eq: $guardian_id }
              }
            }
          }
          {
            first_relationship: {
              _and: {
                active: { _eq: true }
                second_person: { _eq: $guardian_id }
              }
            }
          }
        ]
      }
    ) {
      id
      avatar
      birth_date
      first_name
      last_name

      forms(
        where: {
          form_template: {
            active: { _eq: true }
            enrollment_period: {
              _and: {
                active: { _eq: true }
                organization_id: { _eq: $organization_id }
              }
            }
          }
          _and: [{ status: { _neq: Deleted } }]
          _or: [
            { is_hidden_from_parent: { _eq: false } }
            { is_hidden_from_parent: { _is_null: true } }
          ]
        }
      ) {
        id
        status
        submitted_at

        previous_offer {
          school {
            id
            name
          }
        }

        previous_waitlist {
          school {
            id
            name
          }
        }

        action_items {
          id
          form_id
          summary
          status
          created_at
          updated_at
        }

        form_template {
          id
          name
          closed_at
          open_at
          form_status_descriptions {
            description
            id
            status
          }

          enrollment_period {
            id
            name
          }

          sections {
            type
            schools_ranking_section {
              ranking_enabled
            }
          }
        }

        form_school_ranks(order_by: { rank: asc_nulls_last }) {
          created_at
          rank
          sub_status
          updated_at

          offers(where: { deleted_at: { _is_null: true } }) {
            id
            status
          }

          waitlists(where: { deleted_at: { _is_null: true } }) {
            id
            status

            waitlist_position {
              position
            }
          }

          school {
            id
            name
          }

          tags {
            id
            enrollment_period_tag {
              id
              name
              description
            }
          }
        }

        grades_answers {
          grade_config {
            label
          }
        }
      }
    }
  }
`;

export const GET_ACTIVE_FORM_TEMPLATES = gql`
  ${ENROLLMENT_PERIOD_BASICS_FRAGMENT}
  ${FORM_SUMMARY_FRAGMENT}
  ${FORM_STATUS_DESCRIPTION}
  query GetActiveFormTemplates($organization_id: uuid!) {
    form_template(
      where: {
        _and: {
          deleted_at: { _is_null: true }
          active: { _eq: true }
          enrollment_period: {
            _and: {
              deleted_at: { _is_null: true }
              organization_id: { _eq: $organization_id }
              active: { _eq: true }
            }
          }
          forms: {
            _or: [
              { is_hidden_from_parent: { _eq: false } }
              { is_hidden_from_parent: { _is_null: true } }
            ]
          }
        }
      }
    ) {
      id
      form_status_descriptions {
        ...FormStatusDescription
      }
      enrollment_period {
        id
        name
        active
      }
      forms(
        where: {
          _or: [
            { is_hidden_from_parent: { _eq: false } }
            { is_hidden_from_parent: { _is_null: true } }
          ]
        }
      ) {
        ...FormSummaryFragment
      }
    }
  }
`;

export const GET_OFFERS = gql`
  query GetOffers($form_id: uuid!) {
    form(where: { id: { _eq: $form_id }, deleted_at: { _is_null: true } }) {
      id
      form_template_id
      person {
        first_name
      }
      grades_answers {
        grade_config {
          label
        }
      }
      form_template {
        enrollment_period {
          name
        }
        sections(where: { type: { _eq: SchoolRankingSection } }) {
          schools_ranking_section {
            ranking_enabled
          }
        }
      }
      form_school_ranks(
        where: { offers: { status: { _eq: Offered } } }
        order_by: { rank: asc_nulls_last }
      ) {
        offers(where: { deleted_at: { _is_null: true } }) {
          id
          status
        }
        school {
          name
        }
        rank
      }
    }
  }
`;

export const GET_ACCEPTED_OFFERS = gql`
  query GetAcceptedOffers($form_id: uuid!) {
    form(where: { id: { _eq: $form_id }, deleted_at: { _is_null: true } }) {
      id
      form_school_ranks(
        where: { offers: { status: { _eq: Accepted } } }
        order_by: { rank: asc_nulls_last }
      ) {
        offers(where: { deleted_at: { _is_null: true } }) {
          id
          status
        }
        school {
          name
        }
      }
    }
  }
`;

export const SCHOOLS_BY_SCHOOL_REFERENCE_IDS = gql`
  query SchoolsBySchoolReferenceIDs($school_reference_ids: [String!]) {
    school(where: { reference_id: { _in: $school_reference_ids } }) {
      id
      reference_id
      name
      grades {
        grade_config {
          id
        }
      }
    }
  }
`;
