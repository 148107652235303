import { gql } from "@apollo/client";

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationFragment on organization {
    id
    name
    path
    timezone_name
    announcements {
      id
      title
      description
      active
      entry_point
      type
    }
  }
`;

export const GET_ORGANIZATION_BY_ID = gql`
  ${ORGANIZATION_FRAGMENT}
  query GetOrganization($id: uuid!) {
    organization_by_pk(id: $id) {
      ...OrganizationFragment
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  ${ORGANIZATION_FRAGMENT}
  query GetOrganizations {
    organization {
      ...OrganizationFragment
    }
  }
`;
