import { List } from "@chakra-ui/react";
import * as GQL from "src/types/graphql";
import { FormSchoolRank } from "./FormSchoolRank";

interface FormSchoolRanksProps {
  formTemplateId: uuid;
  form: Pick<GQL.FormSummaryFragment, "id" | "status">;
  formSchoolRanks: GQL.FormSummaryFragment_form_school_ranks[];
  gradeLabel: string;
  studentName: string;
  rankingEnabled: boolean;
}

export function FormSchoolRanks(props: FormSchoolRanksProps) {
  const { form, formSchoolRanks, gradeLabel, studentName, rankingEnabled } =
    props;

  const acceptedOfferSchoolName = formSchoolRanks.find(
    (schoolRank) =>
      schoolRank.offers[0]?.status === GQL.offer_status_enum.Accepted
  )?.school?.name;

  return (
    <List
      display="flex"
      flexDirection="column"
      fontSize="sm"
      gap={4}
      styleType={rankingEnabled ? "none" : "disc"}
      ml={0}
      mt={4}
    >
      {formSchoolRanks.map((schoolRank) => (
        <FormSchoolRank
          formTemplateId={props.formTemplateId}
          acceptedOfferSchoolName={acceptedOfferSchoolName}
          form={form}
          offer={schoolRank.offers[0] ?? null}
          formSchoolRank={schoolRank}
          waitlist={schoolRank.waitlists[0] ?? null}
          gradeLabel={gradeLabel}
          key={schoolRank.rank}
          school={schoolRank.school}
          studentName={studentName}
          rankingEnabled={rankingEnabled}
        />
      ))}
    </List>
  );
}
