import { Flex, Skeleton, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
import { NotFound } from "src/components/Feedback/NotFound";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import {
  useLazyRemoteDataQuery,
  useRemoteDataQuery,
} from "src/hooks/useRemoteDataQuery";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { OrganizationForm, FormikForm } from "./Form";
import {
  CREATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  UPDATE_ORGANIZATION,
} from "./graphql/mutations";
import { GET_ORGANIZATIONS, GET_ORGANIZATION_BY_ID } from "./graphql/queries";
import { useOrgService } from "./hooks/useOrgService";
import * as RD from "src/types/remoteData";
import { identity } from "lodash";
import { GenericError } from "src/components/Feedback/GenericError";
import { useState } from "react";

export const Edit = () => {
  const { id = "" } = useParams();
  const toast = useToast();

  const [updating, setUpdating] = useState(false);

  const { remoteData: organizationRD } = useRemoteDataQuery<
    GQL.GetOrganization,
    GQL.GetOrganizationVariables
  >(GET_ORGANIZATION_BY_ID, {
    variables: { id },
  });

  const [updateOrganization] = useRemoteDataMutation<
    GQL.UpdateOrganization,
    GQL.UpdateOrganizationVariables
  >(UPDATE_ORGANIZATION);

  const [getOrganizations] = useLazyRemoteDataQuery<GQL.GetOrganizations>(
    GET_ORGANIZATIONS,
    {
      fetchPolicy: "network-only",
    }
  );

  const [createAnnouncement] = useRemoteDataMutation<
    GQL.CreateAnnouncement,
    GQL.CreateAnnouncementVariables
  >(CREATE_ANNOUNCEMENT);

  const [updateAnnouncement] = useRemoteDataMutation<
    GQL.UpdateAnnouncement,
    GQL.UpdateAnnouncementVariables
  >(UPDATE_ANNOUNCEMENT);

  const navigate = useNavigate();
  const orgPath = organizationRD.andThen((org) =>
    org.organization_by_pk?.path
      ? RD.success<any, string>(org.organization_by_pk.path)
      : RD.failure<any, string>(new Error("Missing organization path"))
  );

  const { allOrgConfigs: configsRD, updateOrgConfigs } = useOrgService({
    orgPath,
  });

  const remoteData = RD.map2(
    organizationRD.mapError<Error>(identity),
    configsRD,
    (organization, configs) => ({
      organization,
      configs,
    })
  );

  const handleSubmit = async (values: FormikForm) => {
    try {
      if (!orgPath.hasData()) {
        throw new Error("Missing organization path");
      }

      setUpdating(true);
      await updateOrganization({
        variables: {
          id,
          organization: {
            path: values.path,
            name: values.name,
            timezone_name: values.timezoneName,
          },
        },
      });

      const announcement = {
        organization_id: id,
        title: values.announcement.title,
        description: values.announcement.description,
        active: values.announcement.active,
        type: values.announcement.type as GQL.announcement_type_enum,
        entry_point: values.announcement
          .entryPoint as GQL.announcement_entry_point_enum,
        condition: GQL.announcement_condition_enum.NO_STUDENT,
      };

      if (values.announcement.id) {
        await updateAnnouncement({
          variables: {
            id: values.announcement.id,
            announcement,
          },
        });
      } else if (
        values.announcement.active &&
        values.announcement.title &&
        values.announcement.description
      ) {
        await createAnnouncement({
          variables: {
            announcement,
          },
        });
      }

      await updateOrgConfigs(orgPath.data, values.organizationConfigs);
      await getOrganizations();
      navigate(Url.Admin.Organizations.index());
    } catch (error) {
      setUpdating(false);
      console.error(error);
      const id = "EditOrganization";
      if (!toast.isActive(id))
        toast({
          id,
          title: "Error editing organization",
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
          status: "error",
        });
    }
  };

  return (
    <RemoteDataView
      remoteData={remoteData}
      error={() => <GenericError />}
      loading={<Loading />}
    >
      {({ organization, configs }) => {
        if (organization.organization_by_pk === null) {
          return <NotFound />;
        }
        return (
          <OrganizationForm
            organization={organization.organization_by_pk}
            configs={configs}
            onSubmit={handleSubmit}
            submitting={updating}
          />
        );
      }}
    </RemoteDataView>
  );
};

const Loading = () => {
  return (
    <Flex gap={8} direction="column" width="100%">
      <Skeleton height="3rem" width="15rem" />

      <Skeleton height="3rem" width="20rem" />
      <Skeleton height="10rem" width="100%" />

      <Skeleton height="3rem" width="20rem" />
      <Skeleton height="10rem" width="100%" />
    </Flex>
  );
};
