import _ from "lodash";
import { Strict } from "src/services/formTemplate/Strict";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as DisclaimerSection from "./disclaimerSection";
import * as GeneralSection from "./generalSection";
import * as PreRankingSection from "./preRankingSection";
import * as SchoolsRankingSection from "./schoolsRankingSection";
import { sortByOrder } from "./sorter";

/**
 * JSON Decoders
 */

export function decodeJSON(value: string): AF.FormTemplate<AF.WithoutId> {
  // TODO: use zod
  return JSON.parse(value);
}

function isSection(
  section: GQL.form_template_section_insert_input | undefined
): section is GQL.form_template_section_insert_input {
  return section !== undefined;
}

/**
 * JSON Transformers
 */
export function toVariable(
  formConfig: AF.FormTemplate<AF.WithoutId>,
  customQuestionTypes: GQL.GetQuestionTypesByOrganization_custom_question_type[],
  formTemplateId?: uuid
): GQL.CreateFormTemplateVariables {
  return {
    form_template: {
      enrollment_period_id: formConfig.enrollmentPeriodId,
      id: formTemplateId,
      name: formConfig.name,
      key: formConfig.key,
      description: formConfig.description,
      sections: {
        data: formConfig.sections
          .map((section, order) =>
            toSection(
              customQuestionTypes,
              formConfig.enrollmentPeriodId,
              section,
              order,
              formTemplateId
            )
          )
          .filter(isSection),
      },
    },
  };
}

function toSection(
  customQuestionTypes: GQL.GetQuestionTypesByOrganization_custom_question_type[],
  enrollmentPeriodId: uuid,
  section: AF.Section<AF.WithoutId> | undefined,
  order: number,
  formTemplateId?: uuid
): GQL.form_template_section_insert_input | undefined {
  if (section === undefined) return undefined;

  switch (section.type) {
    case AF.PreRankingSectionType:
      return toPreRankingSection(
        customQuestionTypes,
        enrollmentPeriodId,
        section,
        order,
        formTemplateId
      );
    case AF.GeneralSectionType:
      return toGeneralSection(
        customQuestionTypes,
        enrollmentPeriodId,
        section,
        order,
        formTemplateId
      );
    case AF.SchoolRankingSectionType:
      return toSchoolRankingSection(section, order);
    case AF.DisclaimerSectionType:
      return toDisclaimerSection(section, order);
  }
}

function toPreRankingSection(
  customQuestionTypes: GQL.GetQuestionTypesByOrganization_custom_question_type[],
  enrollmentPeriodId: uuid,
  section: AF.PreRankingSection<AF.WithoutId>,
  order: number,
  formTemplateId?: uuid
): GQL.form_template_section_insert_input {
  return {
    order,
    type: GQL.form_template_section_type_enum.PreRankingSection,
    title: section.title,
    description: section.description,
    key: section.key,
    permission_level: section.permissionLevel,
    questions: {
      data: section.questions.map((question, order) => {
        return toQuestion(
          customQuestionTypes,
          enrollmentPeriodId,
          question,
          order,
          formTemplateId
        );
      }),
    },
  };
}

function toGeneralSection(
  customQuestionTypes: GQL.GetQuestionTypesByOrganization_custom_question_type[],
  enrollmentPeriodId: uuid,
  section: AF.GeneralSection<AF.WithoutId>,
  order: number,
  formTemplateId?: uuid
): GQL.form_template_section_insert_input {
  return {
    order,
    type: GQL.form_template_section_type_enum.GeneralSection,
    title: section.title,
    description: section.description,
    key: section.key,
    permission_level: section.permissionLevel,
    questions: {
      data: section.questions.map((question, order) =>
        toQuestion(
          customQuestionTypes,
          enrollmentPeriodId,
          question,
          order,
          formTemplateId
        )
      ),
    },
  };
}
function toSchoolRankingSection(
  section: AF.SchoolRankingSection<AF.WithoutId>,
  order: number
): GQL.form_template_section_insert_input {
  return {
    order,
    type: GQL.form_template_section_type_enum.SchoolRankingSection,
    title: section.title,
    description: section.description,
    key: section.key,
    permission_level: section.permissionLevel,
    schools_ranking_section: {
      data: {
        min_schools: section.minSchools,
        max_schools: section.maxSchools,
        explore_url: section.exploreUrl,
        ranking_enabled: section.rankingEnabled,
      },
    },
  };
}

function toDisclaimerSection(
  section: AF.DisclaimerSection<AF.WithoutId>,
  order: number
): GQL.form_template_section_insert_input {
  return {
    order,
    type: GQL.form_template_section_type_enum.DisclaimerSection,
    title: section.title,
    description: section.description,
    key: section.key,
    permission_level: section.permissionLevel,
    disclaimer_section: {
      data: {
        disclaimer: section.disclaimer,
      },
    },
  };
}

function toType(type: AF.QuestionType): GQL.question_type_enum {
  switch (type) {
    case AF.MultiSelectType:
      return GQL.question_type_enum.MultiSelect;
    case AF.SingleSelectType:
      return GQL.question_type_enum.SingleSelect;
    case AF.FreeTextType:
      return GQL.question_type_enum.FreeText;
    case AF.FileUploadType:
      return GQL.question_type_enum.FileUpload;
    case AF.EmailType:
      return GQL.question_type_enum.Email;
    case AF.PhoneNumberType:
      return GQL.question_type_enum.PhoneNumber;
    case AF.AddressType:
      return GQL.question_type_enum.Address;
    case AF.GradesType:
      throw GQL.question_type_enum.Grades;
    case AF.CustomQuestionType:
      return GQL.question_type_enum.CustomQuestion;
    default:
      const _exhaustiveCheck: never = type;
      return _exhaustiveCheck;
  }
}

function toCategory(category: AF.Category): GQL.form_question_category_enum {
  switch (category) {
    case AF.GeneralCategoryType:
      return GQL.form_question_category_enum.GeneralQuestion;
    case AF.EligibilityCategoryType:
      return GQL.form_question_category_enum.EligibilityQuestion;
  }
}
function toSchools(
  question: AF.FormQuestion<AF.WithoutId>
): GQL.form_question_school_arr_rel_insert_input {
  return {
    data:
      question.specificToSchools?.map((schoolId) => ({
        school_id: schoolId,
      })) ?? [],
  };
}

function toEligibilitySchools(
  option: AF.Option<AF.WithoutId>
): GQL.eligibility_question_school_arr_rel_insert_input {
  switch (option.eligibilityFilter) {
    case "Eligible":
      return {
        data: option.eligibleSchoolIds.map((schoolId) => ({
          school_id: schoolId,
          is_eligible: true,
        })),
      };
    case "NotEligible":
      return {
        data: option.notEligibleSchoolIds.map((schoolId) => ({
          school_id: schoolId,
          is_eligible: false,
        })),
      };

    case "NotApplicable":
    case undefined:
      return {
        data: [],
      };

    default:
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
  }
}

/**
 * GENERAL QUESTION
 */

function toGeneralCategoryQuestion(
  enrollmentPeriodId: uuid,
  question: AF.Question<AF.WithoutId>,
  order: number,
  formTemplateId?: uuid
): GQL.question_insert_input {
  if (question.type === AF.GradesType) {
    throw new Error(
      `Invalid question type, "Grades" is not a valid type for "GeneralCategory" question`
    );
  }

  return {
    order,
    question: question.question,
    permission_level: question.permissionLevel,
    is_required: question.requirement === "Required",
    key: question.key,
    type: toType(question.type),
    form_question: {
      data: {
        category: toCategory(question.category),
        form_question_options: toGeneralCategoryQuestionOptions(
          enrollmentPeriodId,
          question,
          formTemplateId
        ),
        form_verification: question.formVerification
          ? toFormVerification(question.formVerification, formTemplateId)
          : undefined,
        filters: question.filters,
      },
    },
    form_question_schools: toSchools(question),
    link_text: question.link_text ?? undefined,
    link_url: question.link_url ?? undefined,
  };
}

function toFormVerification(
  formVerification: AF.FormVerification<AF.WithoutId>,
  formTemplateId?: uuid
): GQL.form_verification_obj_rel_insert_input {
  return {
    data: {
      label: formVerification.label,
      form_template_id: formTemplateId,
    },
    // Verifications that share the same label represent the same verification.
    on_conflict: {
      constraint:
        GQL.form_verification_constraint
          .form_verification_form_template_id_label_key,
      update_columns: [GQL.form_verification_update_column.label],
    },
  };
}

function toQuestionOption(
  option: AF.Option<AF.WithoutId>
): GQL.form_question_option_insert_input {
  return {
    label: option.label,
    value: option.value,
    additional_questions: null,
    translate_options: option.translate_options,
    skip_verification: option.skipVerification,
  };
}

function toSingleSelectQuestionOption(
  enrollmentPeriodId: uuid,
  option: AF.Option<AF.WithoutId>,
  formTemplateId?: uuid
): GQL.form_question_option_insert_input {
  return {
    ...toQuestionOption(option),
    additional_questions: option.additionalQuestions
      ? toAdditionalQuestions(
          enrollmentPeriodId,
          option.additionalQuestions,
          formTemplateId
        )
      : undefined,
  };
}

function toGeneralCategoryQuestionOptions(
  enrollmentPeriodId: uuid,
  question: AF.Question<AF.WithoutId>,
  formTemplateId?: uuid
): GQL.form_question_option_arr_rel_insert_input {
  switch (question.type) {
    case AF.MultiSelectType:
      return {
        data: question.options.map(
          (option, order): GQL.form_question_option_insert_input => {
            return {
              ...toQuestionOption(option),
              order,
            };
          }
        ),
      };
    case AF.SingleSelectType:
      return {
        data: question.options.map(
          (option, order): GQL.form_question_option_insert_input => {
            return {
              ...toSingleSelectQuestionOption(
                enrollmentPeriodId,
                option,
                formTemplateId
              ),
              order,
            };
          }
        ),
      };
    case AF.FreeTextType:
    case AF.FileUploadType:
    case AF.GradesType:
    case AF.EmailType:
    case AF.PhoneNumberType:
    case AF.AddressType:
    case AF.CustomQuestionType:
      return { data: [] };

    default:
      const _exhaustiveCheck: never = question;
      return _exhaustiveCheck;
  }
}

export function toQuestion(
  customQuestionTypes: GQL.GetQuestionTypesByOrganization_custom_question_type[],
  enrollmentPeriodId: uuid,
  question: AF.Question<AF.WithoutId>,
  order: number,
  formTemplateId?: uuid
): GQL.question_insert_input {
  switch (question.type) {
    case AF.GradesType:
      return toGradesQuestion(
        enrollmentPeriodId,
        question,
        order,
        formTemplateId
      );
    case AF.CustomQuestionType:
      return formatCustomQuestionPayload(customQuestionTypes, question, order);
  }

  switch (question.category) {
    case AF.EligibilityCategoryType:
      return toEligibilityCategoryQuestion(
        enrollmentPeriodId,
        question,
        order,
        formTemplateId
      );
    case AF.GeneralCategoryType:
      return toGeneralCategoryQuestion(
        enrollmentPeriodId,
        question,
        order,
        formTemplateId
      );
    default:
      const _exhaustiveCheck: never = question;
      return _exhaustiveCheck;
  }
}

/**
 * PRE RANKING QUESTIONS
 */

function toEligibilityCategoryQuestion(
  enrollmentPeriodId: uuid,
  question: AF.Question<AF.WithoutId>,
  order: number,
  formTemplateId?: uuid
): GQL.question_insert_input {
  switch (question.type) {
    case AF.GradesType:
      throw new Error(
        `Invalid question type, "Grades" is not a valid type for "EligibilityCategory" question`
      );
  }
  return {
    order,
    question: question.question,
    permission_level: question.permissionLevel,
    is_required: question.requirement === "Required",
    key: question.key,
    type: toType(question.type),
    form_question: {
      data: {
        category: toCategory(question.category),
        form_question_options: toPreRankingQuestionOptions(
          enrollmentPeriodId,
          question,
          formTemplateId
        ),
        form_verification: question.formVerification
          ? toFormVerification(question.formVerification, formTemplateId)
          : undefined,
        filters: question.filters,
      },
    },
    link_text: question.link_text ?? undefined,
    link_url: question.link_url ?? undefined,
  };
}

function toPreRankingQuestionOptions(
  enrollmentPeriodId: uuid,
  question: AF.Question<AF.WithoutId>,
  formTemplateId?: uuid
): GQL.form_question_option_arr_rel_insert_input {
  switch (question.type) {
    case AF.GradesType:
      throw new Error("Grades question doesn't have options value");
  }
  switch (question.category) {
    case AF.GeneralCategoryType:
      return toGeneralCategoryQuestionOptions(
        enrollmentPeriodId,
        question,
        formTemplateId
      );
    case AF.EligibilityCategoryType:
      return toEligibilityCategoryQuestionOptions(
        enrollmentPeriodId,
        question,
        formTemplateId
      );
  }
}

function toGradesQuestion(
  enrollmentPeriodId: uuid,
  question: AF.Question<AF.WithoutId>,
  order: number,
  formTemplateId?: uuid
): GQL.question_insert_input {
  if (question.type !== "Grades") {
    throw new Error(
      `Invalid question type, unable to convert ${question.type} into a "Grades" question.`
    );
  }
  return {
    order,
    type: GQL.question_type_enum.Grades,
    question: question.question,
    permission_level: question.permissionLevel,
    is_required: question.requirement === "Required",
    grades_question: {
      data: {
        filters: question.filters,
        grades_additional_questions: {
          data: (question.gradesAdditionalQuestions ?? [])?.flatMap((gaq) =>
            (gaq.additionalQuestions ?? [])?.map(
              (
                additionalQuestion,
                additionalQuestionOrder: number
              ): GQL.grades_additional_question_insert_input => {
                return {
                  grade_config_id: gaq.gradeConfigId,
                  question: {
                    data: toQuestion(
                      [], // CQTs not supported as grades additional question
                      enrollmentPeriodId,
                      additionalQuestion,
                      additionalQuestionOrder,
                      formTemplateId
                    ),
                  },
                };
              }
            )
          ),
        },
      },
    },
    link_text: question.link_text ?? undefined,
    link_url: question.link_url ?? undefined,
  };
}

function formatCustomQuestionPayload(
  customQuestionTypes: GQL.GetQuestionTypesByOrganization_custom_question_type[],
  question: AF.Question<AF.WithoutId>,
  order: number
): GQL.question_insert_input {
  if (question.type !== AF.CustomQuestionType) {
    throw new Error(
      `Invalid question type, unable to convert ${question.type} into a "${AF.CustomQuestionType}" question.`
    );
  }

  const customQuestionType = customQuestionTypes.find((cqt) => {
    return cqt.id === question.customQuestionTypeId;
  });

  if (!customQuestionType) {
    throw new Error(
      `The custom question type for this question does not exist.`
    );
  }

  return {
    order,
    type: GQL.question_type_enum.CustomQuestion,
    question: question.question,
    permission_level: question.permissionLevel,
    is_required: question.requirement === "Required",
    custom_question: {
      data: {
        custom_question_type_id: question.customQuestionTypeId,
        custom_question_relationships: {
          data: formatNestedQuestionsPayload(customQuestionType).map(
            ([customQuestionTypeFieldId, payload]) => {
              return {
                custom_question_type_field_id: customQuestionTypeFieldId,
                cloned_question: {
                  data: payload,
                },
              };
            }
          ),
        },
      },
    },
    link_text: question.link_text ?? undefined,
    link_url: question.link_url ?? undefined,
  };
}

/**
 * Create new question and form_question records based on the values of the
 * corresponding CustomQuestionType's fields's questions.
 *
 * Explicitly show the properties that are being propagated.
 * Do not use spread operator as it will obfuscate how the values are assigned.
 */
function formatNestedQuestionsPayload(
  customQuestionType: GQL.GetQuestionTypesByOrganization_custom_question_type
): [string, GQL.question_insert_input][] {
  return customQuestionType.custom_question_type_fields.map((field) => {
    const customQuestionTypeFieldId = field.question_id;

    const {
      key,
      order,
      type,
      question,
      permission_level,
      is_required,
      link_text,
      link_url,
      form_question,
    } = field.question;

    const formQuestionOptions = form_question?.form_question_options ?? [];

    const payload = {
      key,
      order,
      type,
      question,
      permission_level,
      is_required,
      link_text,
      link_url,
      form_question: {
        data: {
          category: form_question?.category,
          form_question_options: {
            data: formQuestionOptions.map((option) => {
              const { label, order, translate_options, value } = option;
              return {
                label,
                order,
                translate_options,
                value,
              };
            }),
          },
        },
      },
    };

    return [customQuestionTypeFieldId, payload];
  });
}

function toEligibilityCategoryQuestionOptions(
  enrollmentPeriodId: uuid,
  question: AF.Question<AF.WithoutId>,
  formTemplateId?: uuid
): GQL.form_question_option_arr_rel_insert_input {
  switch (question.type) {
    case AF.SingleSelectType:
      return {
        data: question.options.map(
          (option, order): GQL.form_question_option_insert_input => {
            return {
              ...toSingleSelectQuestionOption(
                enrollmentPeriodId,
                option,
                formTemplateId
              ),
              order,
              eligibility_schools: toEligibilitySchools(option),
            };
          }
        ),
      };
    case AF.AddressType:
      return {
        // TODO: 2023/09/01 Abadi is working on the eligibility logic
        data: [],
      };
    case AF.GradesType:
    case AF.MultiSelectType:
    case AF.FreeTextType:
    case AF.FileUploadType:
    case AF.EmailType:
    case AF.PhoneNumberType:
    case AF.CustomQuestionType:
      throw new Error(
        "Only SingleSelect type is supported for eligibility category question"
      );
    default:
      const _exhaustiveCheck: never = question;
      return _exhaustiveCheck;
  }
}

export function toAdditionalQuestions(
  enrollmentPeriodId: uuid,
  additionalQuestions: AF.Question<AF.WithoutId>[],
  formTemplateId?: uuid
): GQL.additional_question_arr_rel_insert_input {
  return {
    data: additionalQuestions.map((q, order) => ({
      question: {
        data: toQuestion([], enrollmentPeriodId, q, order, formTemplateId),
      },
    })),
  };
}

function buildGeneralSections(
  sortedSections: GQL.FormTemplateFragment_sections[],
  strict: Strict = "strict"
) {
  validateGeneralSection(sortedSections);
  return sortedSections
    .filter(
      (section) =>
        section.type === GQL.form_template_section_type_enum.GeneralSection
    )
    .map((value) => GeneralSection.fromGQL(value, strict));
}

function buildDisclaimerSection(
  sortedSections: GQL.FormTemplateFragment_sections[]
) {
  validateDisclaimerSection(sortedSections);

  const lastSection = sortedSections[sortedSections.length - 1];
  return lastSection?.type ===
    GQL.form_template_section_type_enum.DisclaimerSection
    ? DisclaimerSection.fromGQL(lastSection)
    : undefined;
}

export function fromGQL(
  value: GQL.FormTemplateFragment,
  strict: Strict = "strict"
): AF.FormTemplate<AF.WithId> {
  const sortedSections = _.sortBy(value.sections, sortByOrder);

  let sections: AF.Sections<AF.WithId> = [];

  const hasPreRankingSection = sortedSections.some(
    (section) =>
      section.type === GQL.form_template_section_type_enum.PreRankingSection
  );

  const hasSchoolRankingSection = sortedSections.some(
    (section) =>
      section.type === GQL.form_template_section_type_enum.SchoolRankingSection
  );

  if (hasPreRankingSection || hasSchoolRankingSection) {
    if (!validatePreRankingSection(sortedSections)) {
      throw new Error("Invalid PreRankingSection");
    }

    if (!validateSchoolsRankingSection(sortedSections)) {
      throw new Error("Invalid SchoolsRankingSection");
    }

    const preRankingSection = PreRankingSection.fromGQL(sortedSections[0]);
    const schoolsRankingSection = SchoolsRankingSection.fromGQL(
      sortedSections[1]
    );

    const generalSections = buildGeneralSections(sortedSections, strict);

    const disclaimerSection = buildDisclaimerSection(sortedSections);

    sections = [
      preRankingSection,
      schoolsRankingSection,
      ...generalSections,
      disclaimerSection,
    ];
  } else {
    const generalSections = buildGeneralSections(sortedSections, strict);

    const disclaimerSection = buildDisclaimerSection(sortedSections);

    sections = [...generalSections, disclaimerSection];
  }

  const count = sections.filter((s) => s !== undefined).length;
  if (count !== sortedSections.length) {
    throw new Error(
      `Unexpected error, the transformed sections count (${count}) doesn't match the original sections count (${sortedSections.length})`
    );
  }

  return {
    id: value.id,
    name: value.name,
    key: value.key,
    description: value.description ?? undefined,
    lotteryOffersEnabled: value.lottery_offers_enabled,
    enrollmentPeriodId: value.enrollment_period_id,
    sections,
  };
}

function validatePreRankingSection(
  sortedSections: GQL.FormTemplateSectionFragment[]
): sortedSections is [
  GQL.FormTemplateSectionFragment,
  ...GQL.FormTemplateSectionFragment[]
] {
  if (sortedSections[0]?.type !== AF.PreRankingSectionType) {
    throw new Error("Invalid data, missing PreRankingSection");
  }

  const count = sortedSections.filter(
    (s) => s.type === AF.PreRankingSectionType
  ).length;
  if (count > 1) {
    throw new Error(
      `Invalid data, expecting only 1 PreRankingSection, but found ${count}`
    );
  }

  return true;
}

function validateSchoolsRankingSection(
  sortedSections: GQL.FormTemplateSectionFragment[]
): sortedSections is [
  GQL.FormTemplateSectionFragment,
  GQL.FormTemplateSectionFragment,
  ...GQL.FormTemplateSectionFragment[]
] {
  if (sortedSections[1]?.type !== AF.SchoolRankingSectionType) {
    throw new Error("Invalid data, missing SchoolsRankingSection");
  }

  const count = sortedSections.filter(
    (s) => s.type === AF.SchoolRankingSectionType
  ).length;
  if (count > 1) {
    throw new Error(
      `Invalid data, expecting only 1 SchoolsRankingSection, but found ${count}`
    );
  }

  return true;
}

function validateGeneralSection(
  sortedSections: GQL.FormTemplateSectionFragment[]
): void {
  const count = sortedSections.filter(
    (s) => s.type === AF.GeneralSectionType
  ).length;
  if (count < 1) {
    throw new Error(
      `Invalid data, expecting at least 1 SchoolsRankingSection, but found ${count}`
    );
  }
}

function validateDisclaimerSection(
  sortedSections: GQL.FormTemplateSectionFragment[]
): void {
  const disclaimerSectionIndex = sortedSections.findIndex(
    (section) =>
      section.type === GQL.form_template_section_type_enum.DisclaimerSection
  );
  if (
    disclaimerSectionIndex > 0 &&
    disclaimerSectionIndex !== sortedSections.length - 1
  ) {
    throw new Error("Invalid data, DisclaimerSection is not the last section");
  }
}

export * as Answer from "./answer";
export * as Question from "./question";
