import {
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Text,
} from "@chakra-ui/react";
import { Field, useField, useFormikContext } from "formik";
import { useCallback, useMemo } from "react";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import { TextareaWithValidationControl } from "src/components/Inputs/TextareaWithValidationControl";
import { ANNOUNCEMENT_ENTRY_POINTS, ANNOUNCEMENT_TYPES } from "src/constants";
import * as GQL from "src/types/graphql";
import { FormikForm } from "../Form";

export const DESCRIPTION_CONTENT_LIMIT = 550;

export function AnnouncementForm() {
  const formik = useFormikContext<FormikForm>();

  const isActive = formik.values.announcement.active;

  const [field, fieldValidation, helpers] = useField(
    "announcement.description"
  );

  const descriptionCounter = useMemo(
    () => DESCRIPTION_CONTENT_LIMIT - field.value.length,
    [field.value]
  );

  const handleOnChangeDescription = useCallback(() => {
    if (fieldValidation.touched) return;

    helpers.setTouched(true);
  }, [helpers, fieldValidation.touched]);

  return (
    <Flex direction="column" gap={4}>
      <Heading as="h3" size="md">
        Alerts
      </Heading>

      <Flex direction="column">
        <SwitchInput<Boolean>
          label="Display informational alert"
          name="announcement.active"
          checked={[true, ""]}
          unchecked={[false, ""]}
          direction="row"
        />

        <Flex direction="column" flex={1} marginLeft={12} gap={2}>
          <Text fontSize="xs" color="blackAlpha.700">
            Enables an alert on either portal, with personalized copy
          </Text>
          {isActive ? (
            <Flex direction="column" gap={2}>
              <Flex gap={2}>
                <FormControl>
                  <FormLabel htmlFor="announcement.type">Type</FormLabel>
                  <Field
                    as={Select}
                    id="announcement.type"
                    name="announcement.type"
                    isDisabled
                  >
                    {(
                      Object.keys(
                        ANNOUNCEMENT_TYPES
                      ) as GQL.announcement_type_enum[]
                    ).map((key) => (
                      <option
                        value={ANNOUNCEMENT_TYPES[key].value}
                        key={ANNOUNCEMENT_TYPES[key].value}
                      >
                        {ANNOUNCEMENT_TYPES[key].label}
                      </option>
                    ))}
                  </Field>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="announcement.entryPoint">
                    Location
                  </FormLabel>
                  <Field
                    as={Select}
                    id="announcement.entryPoint"
                    name="announcement.entryPoint"
                    isDisabled
                  >
                    {(
                      Object.keys(
                        ANNOUNCEMENT_ENTRY_POINTS
                      ) as GQL.announcement_entry_point_enum[]
                    ).map((key) => (
                      <option
                        value={ANNOUNCEMENT_ENTRY_POINTS[key].value}
                        key={ANNOUNCEMENT_ENTRY_POINTS[key].value}
                      >
                        {ANNOUNCEMENT_ENTRY_POINTS[key].label}
                      </option>
                    ))}
                  </Field>
                </FormControl>
              </Flex>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <InputWithValidationControl
                  inputProps={{ placeholder: "Enter a title" }}
                  name="announcement.title"
                  isRequired
                />
              </FormControl>

              <FormControl>
                <FormLabel>Description</FormLabel>
                <TextareaWithValidationControl
                  textareaProps={{ placeholder: "Enter a description", mb: 2 }}
                  name="announcement.description"
                  isRequired
                  onChange={handleOnChangeDescription}
                />

                {(!fieldValidation.touched || !fieldValidation.error) && (
                  <Text fontSize="sm" lineHeight="1rem">
                    {descriptionCounter}
                  </Text>
                )}
              </FormControl>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
}
