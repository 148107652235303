import { Skeleton } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { GET_MESSAGE_TEMPLATE_TYPES } from "../graphql/queries";
import { formatInitialValues, formatMessageTemplates } from "./helpers";
import { EditFormTemplateMessagesProvider } from "./context";
import { MessageTemplatesForm } from "./MessageTemplatesForm";
import { useOrganization } from "src/hooks/useOrganization";

export const ListMessageTemplates: FunctionComponent = () => {
  const organization = useOrganization();
  const { formTemplateId = "" } = useParams();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetMessageTemplateTypes,
    GQL.GetMessageTemplateTypesVariables
  >(GET_MESSAGE_TEMPLATE_TYPES, {
    variables: {
      formTemplateId: formTemplateId
    },
    skip: !formTemplateId
  });

  return (
    <GQLRemoteDataView
      remoteData={remoteData}
      error={() => <GenericError />}
      loading={<Skeleton width="16rem" height="2.25rem" />}
    >
      {(data) => {
        /* TODO Start: Remove this when waitlist table big fix is up*/
        const orgsWithWaitlistDisabled = ["nolaps", "tulsa"];
        const isWaitlistDisabled = orgsWithWaitlistDisabled.includes(
          organization.map((org) => org.path).withDefault("")
        );

        let rawMessageTemplates;
        if (isWaitlistDisabled) {
          const waitlistTemplateRemoved = data.message_template_type.filter(
            (messageTemplateType) => {
              return (
                messageTemplateType.value !==
                  GQL.message_template_type_enum.Waitlisted &&
                messageTemplateType.value !==
                  GQL.message_template_type_enum.WaitlistRemoved &&
                messageTemplateType.value !==
                  GQL.message_template_type_enum.WaitlistWithdrawn
              );
            }
          );

          rawMessageTemplates = {
            ...data,
            message_template_type: waitlistTemplateRemoved
          };
        } else {
          rawMessageTemplates = data;
        }
        /* TODO End */

        const messageTemplates = formatMessageTemplates(
          rawMessageTemplates,
          formTemplateId
        );

        const initialValues = formatInitialValues(messageTemplates);

        return (
          <EditFormTemplateMessagesProvider
            formTemplateId={formTemplateId}
            listMessagesInitialValues={initialValues}
          >
            <MessageTemplatesForm messageTemplates={messageTemplates} />
          </EditFormTemplateMessagesProvider>
        );
      }}
    </GQLRemoteDataView>
  );
};
