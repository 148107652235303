import { ApolloError } from "@apollo/client";
import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import React from "react";
import {
  useBoundariesMap,
  useFormAddressPosition,
} from "src/components/Boundary/useBoundary";
import { SchoolRankingEditList } from "src/components/Form/SchoolRankingEditList";
import { useGradeAnswer } from "src/components/Form/useGradeAnswer";
import { Glossary } from "src/components/Text/Glossary";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useGlossary } from "src/hooks/useGlossary";
import { SchoolWithFakeRank } from "src/scenes/parent/forms/LateEdit";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { GQLRemoteDataView } from "../../Layout/RemoteDataView";
import { MultiSelectOrderableList } from "../../MultiSelectOrderableList";
import { schoolSelectionLimitText } from "./helpers";
import { SchoolListItem } from "./SchoolListItem";
import { useEligibleSchools } from "./useEligibleSchools";

export type School = {
  id: uuid;
  name: string;
  street_address: string | null;
};
export type RankedSchool = {
  rank: number;
  school: School;
};

interface LateMultiSelectSchoolRankProps {
  formId: uuid;
  preRankingSection: AF.PreRankingSection<AF.WithId>;
  schoolRankingSection: AF.SchoolRankingSection<AF.WithId>;
  newSchools: SchoolWithFakeRank[];
  selectedSchoolsRank: readonly GQL.GetSchoolsRank_form_school_rank[];
  onIsDropdownOpenChange?: (isOpen: boolean) => void;
  onLateDeleteSchoolRank: (
    schoolRank: GQL.GetSchoolsRank_form_school_rank
  ) => void;
  onLateSelectedItem: (school: School) => void;
  onLateDeleteNewSchool: (school: School) => void;
}

export const LateMultiSelectSchoolRank: React.FC<
  LateMultiSelectSchoolRankProps
> = ({
  formId,
  preRankingSection,
  schoolRankingSection,
  onIsDropdownOpenChange,
  newSchools,
  selectedSchoolsRank,
  onLateDeleteSchoolRank,
  onLateSelectedItem,
  onLateDeleteNewSchool,
}) => {
  const { glossary } = useGlossary();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const addressLocationRemoteData = useFormAddressPosition({
    formId,
  }).mapError((e) => new ApolloError({ errorMessage: e.message }));
  const boundariesMapRemoteData = useBoundariesMap({ formId });
  const gradeConfigRemoteData = useGradeAnswer({ formId });

  const selectedSchools = React.useMemo(
    () => [
      ...selectedSchoolsRank?.map(
        (form_school_rank) => form_school_rank.school
      ),
      ...newSchools,
    ],
    [selectedSchoolsRank, newSchools]
  );

  const eligibleSchoolsRemoteData = useEligibleSchools({
    formId,
    preRankingSection,
    selectedSchools,
  });
  const schoolLimitReached =
    schoolRankingSection.maxSchools === undefined
      ? false
      : selectedSchools.length >= schoolRankingSection.maxSchools;
  const renderListItem = (school: School) => (
    <SchoolListItem
      school={school}
      addressLocation={addressLocationRemoteData}
      boundariesMap={boundariesMapRemoteData}
      gradeConfigId={gradeConfigRemoteData}
      showAddress
      textProps={{ fontWeight: 600 }}
    />
  );

  const renderSelectItem = (school: School) => (
    <SchoolListItem
      school={school}
      addressLocation={addressLocationRemoteData}
      boundariesMap={boundariesMapRemoteData}
      gradeConfigId={gradeConfigRemoteData}
      textProps={{ fontWeight: 600 }}
    />
  );
  const {
    confirm: confirmLateDeletion,
    confirmationDialog: lateDeleteConfirmationDialog,
    setBody,
  } = useConfirmationDialog({
    header: glossary`Remove school?`,
    body: null,
    cancelButton: {
      label: "No, cancel",
    },
    confirmButton: {
      label: "Yes, remove",
      colorScheme: "red",
    },
    translate: true,
  });

  const handleLateDeleteSchoolRank = async (
    schoolRank: GQL.GetSchoolsRank_form_school_rank
  ) => {
    if (
      schoolRank.sub_status === GQL.offer_status_enum.Accepted ||
      schoolRank.sub_status === GQL.offer_status_enum.Offered
    ) {
      setBody(
        <Text>
          This will remove it from your child’s form and decline the offer.
        </Text>
      );
    } else if (schoolRank.sub_status === GQL.waitlist_status_enum.Waitlisted) {
      setBody(
        <Text>
          This will remove it from your child’s form and withdraw from the
          waitlist.
        </Text>
      );
    } else {
      setBody(<Text>This will remove it from your child’s form.</Text>);
    }
    if (!(await confirmLateDeletion({}))) {
      return;
    }

    onLateDeleteSchoolRank(schoolRank);
  };

  const handleLateDeleteNewSchool = async (school: School) => {
    setBody(<Text>This will remove it from your child’s form.</Text>);

    if (!(await confirmLateDeletion({}))) {
      return;
    }
    onLateDeleteNewSchool(school);
  };

  const renderSelectedItems = (schools: readonly School[]) => (
    <Flex
      direction="column"
      alignItems="center"
      maxWidth="25rem"
      width="100%"
      alignSelf="center"
    >
      {schools.length === 0 ? (
        <Box fontSize="xs" fontWeight="600">
          <Glossary>There are no schools selected</Glossary>
        </Box>
      ) : (
        <SchoolRankingEditList
          newSchools={newSchools}
          schoolsRank={selectedSchoolsRank}
          onDeleteSchoolRank={handleLateDeleteSchoolRank}
          onDeleteNewSchool={handleLateDeleteNewSchool}
          renderSelectItem={renderSelectItem}
          rankingEnabled={schoolRankingSection.rankingEnabled ?? false}
        />
      )}
    </Flex>
  );

  let schoolSelectionLimit: React.ReactNode = null;
  if (!isDropdownOpen) {
    const text = schoolSelectionLimitText(glossary, {
      limitReached: schoolLimitReached,
      maxSchools: schoolRankingSection.maxSchools,
      minSchools: schoolRankingSection.minSchools,
    });

    if (text) {
      schoolSelectionLimit = (
        <Text
          textAlign="center"
          alignSelf="center"
          color="gray.500"
          fontSize="sm"
        >
          {text}
        </Text>
      );
    }
  }

  return (
    <Box width="100%" position="relative" display="block">
      <GQLRemoteDataView
        remoteData={eligibleSchoolsRemoteData}
        loading={<Loading />}
      >
        {(eligibleSchools) => {
          return (
            <>
              <MultiSelectOrderableList<School>
                items={eligibleSchools}
                selectedItems={selectedSchools}
                keyAttr="id"
                searchableFields={["name", "street_address"]}
                onSelectedItemChange={(school) => onLateSelectedItem(school)}
                renderListItem={renderListItem}
                renderSelectedItems={renderSelectedItems}
                button={{
                  label: glossary`Add school`,
                  disabled: schoolLimitReached,
                }}
                onIsDropdownOpenChange={(isOpen) => {
                  setIsDropdownOpen(isOpen);
                  if (onIsDropdownOpenChange) onIsDropdownOpenChange(isOpen);
                }}
              />

              {schoolSelectionLimit}
            </>
          );
        }}
      </GQLRemoteDataView>
      {lateDeleteConfirmationDialog}
    </Box>
  );
};

const Loading = () => (
  <Flex width="25rem" direction="column" padding="2rem" gap="6">
    <Flex border="px solid red" height="1rem" width="100%" paddingX="2rem">
      <Skeleton height="1rem" width="100%" borderRadius="md" />
    </Flex>
    <Flex direction="column" gap="3" alignItems="center">
      <Skeleton height="2rem" width="100%" borderRadius="md" />
      <Skeleton height="2rem" width="100%" borderRadius="md" />
      <Skeleton height="2rem" width="100%" borderRadius="md" />
    </Flex>
  </Flex>
);
