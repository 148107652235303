import { Flex, Tag, Text, Tooltip } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FunctionComponent, useCallback, useMemo } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";
import { GENERIC_ERROR, MESSAGE_TEMPLATES } from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { FormTemplateDefaultCard } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/components/FormTemplateDefaultCard";
import { FormTemplateOutletContext } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/Edit";
import {
  FormTemplateId,
  hasChanges,
  toOriginal
} from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/common";
import { MessageTemplate } from "src/services/url/OrgAdmin";
import * as GQL from "src/types/graphql";
import { EditFormTemplateActionBar } from "../../components/ActionBar";
import { UPDATE_MESSAGE_TEMPLATES } from "../graphql/mutations";
import { GET_MESSAGE_TEMPLATE_TYPES } from "../graphql/queries";
import { StatusSwitch } from "./components/StatusSwitch";
import { useEditFormTemplateMessagesContext } from "./context";
import { transformFormValuesToMutationVariables } from "./helpers";
import { ListMessageTemplatesForm, MessageTemplateType } from "./schemas";

const FORM_ID = "edit-form-templates-list-message-templates";

interface Props {
  messageTemplates: MessageTemplateType[];
}

export const MessageTemplatesForm: FunctionComponent<Props> = ({
  messageTemplates
}) => {
  const organization = useOrganization();
  const { selectedEditFormTemplate } = useFormTemplates();
  const { formTemplateId = "", enrollmentPeriodId = "" } = useParams();
  const { state, dispatch } = useEditFormTemplateMessagesContext();
  const { actionBarRef } = useOutletContext<FormTemplateOutletContext>();

  const [updateMessageTemplates] = useRemoteDataMutation<
    GQL.UpdateMessageTemplates,
    GQL.UpdateMessageTemplatesVariables
  >(UPDATE_MESSAGE_TEMPLATES);

  const toast = useAvelaToast();

  const onSubmit = useCallback(
    async (values: ListMessageTemplatesForm) => {
      try {
        const mutationVariables = transformFormValuesToMutationVariables(
          values,
          selectedEditFormTemplate?.ranking_enabled ?? false
        );
        const result = await updateMessageTemplates({
          variables: mutationVariables,
          refetchQueries: [GET_MESSAGE_TEMPLATE_TYPES]
        });

        const returning = result.data?.insert_message_template?.returning;
        if (returning && returning.length > 0) {
          for (const row of returning) {
            const value = values[row.type];
            if (value) value.id = row.id;
          }
        }

        if (dispatch) {
          dispatch({
            type: "Refresh",
            initialValues: { listMessages: values },
            formTemplateId
          });
        }

        toast({
          status: "success",
          title: "Automatic messages updated",
          isClosable: true,
          containerStyle: {
            marginBottom: "6rem"
          }
        });
      } catch (error: unknown) {
        console.error(error);
        toast.error(GENERIC_ERROR);
      }
    },
    [
      selectedEditFormTemplate,
      updateMessageTemplates,
      dispatch,
      toast,
      formTemplateId
    ]
  );

  const isOfferRelatedMessage = useCallback(
    (messageTemplateType: GQL.message_template_type_enum) => {
      return (
        messageTemplateType === GQL.message_template_type_enum.OfferAccepted ||
        messageTemplateType === GQL.message_template_type_enum.OfferDeclined ||
        messageTemplateType === GQL.message_template_type_enum.OfferExtended ||
        messageTemplateType === GQL.message_template_type_enum.OfferRevoked
      );
    },
    []
  );

  const handleValidate = useCallback(
    (values: ListMessageTemplatesForm) => {
      if (dispatch) {
        dispatch({
          type: "UpdateListMessages",
          listMessages: {
            isDraft: true,
            ...values
          },
          formTemplateId
        });

        dispatch({
          type: "Save",
          formTemplateId
        });
      }
    },
    [formTemplateId, dispatch]
  );

  const draft = useMemo(
    () =>
      state?.get("listMessages")?.get(FormTemplateId(formTemplateId))?.draft,
    [formTemplateId, state]
  );

  const original = useMemo(
    () =>
      state?.get("listMessages")?.get(FormTemplateId(formTemplateId))?.original,
    [formTemplateId, state]
  );

  const formikInitialValues = useMemo(() => {
    if (draft) {
      return toOriginal(draft);
    } else {
      return original;
    }
  }, [draft, original]);

  const hasChangesResult = hasChanges(original, draft);

  if (!formikInitialValues) return <GenericError />;

  return (
    <Formik<ListMessageTemplatesForm>
      initialValues={formikInitialValues}
      onSubmit={onSubmit}
      validate={handleValidate}
      enableReinitialize
    >
      {({ resetForm }) => {
        const handleResetForm = () => {
          resetForm();

          if (dispatch) {
            dispatch({
              type: "Reset",
              formTemplateId
            });
          }
        };

        return (
          <Flex direction="column" as={Form} id={FORM_ID} gap={4} mt={4}>
            {messageTemplates.map((messageTemplate, index) => (
              <FormTemplateDefaultCard
                key={index}
                title={MESSAGE_TEMPLATES[messageTemplate.type].label}
                previewRoute={organization
                  .map((org) =>
                    MessageTemplate.edit(
                      org,
                      enrollmentPeriodId,
                      formTemplateId,
                      messageTemplate.type,
                      true
                    )
                  )
                  .withDefault("#")}
                editRoute={organization
                  .map((org) =>
                    MessageTemplate.edit(
                      org,
                      enrollmentPeriodId,
                      formTemplateId,
                      messageTemplate.type,
                      false
                    )
                  )
                  .withDefault("#")}
                tag={
                  isOfferRelatedMessage(messageTemplate.type) && (
                    <Tag
                      size="sm"
                      variant="solid"
                      color="blackAlpha.900"
                      bg="gray.200"
                      width="fit-content"
                      fontSize="xs"
                    >
                      Lottery & offers
                    </Tag>
                  )
                }
                actionSwitch={
                  !selectedEditFormTemplate?.lottery_offers_enabled &&
                  isOfferRelatedMessage(messageTemplate.type) ? (
                    <Tooltip
                      label={
                        <Text align="center">
                          To activate this message, first turn on Lottery &
                          Offers in Settings
                        </Text>
                      }
                      hasArrow
                      placement="top"
                      borderRadius="md"
                      w={250}
                      p={2}
                    >
                      <Flex>
                        <StatusSwitch
                          templateType={messageTemplate.type}
                          lotteryAndOffersEnabled={
                            selectedEditFormTemplate?.lottery_offers_enabled ??
                            false
                          }
                        />
                      </Flex>
                    </Tooltip>
                  ) : (
                    <StatusSwitch
                      templateType={messageTemplate.type}
                      lotteryAndOffersEnabled={
                        selectedEditFormTemplate?.lottery_offers_enabled ??
                        false
                      }
                    />
                  )
                }
              />
            ))}
            <EditFormTemplateActionBar
              actionBarRef={actionBarRef}
              formId={FORM_ID}
              hasChanges={hasChangesResult}
              onClearChanges={handleResetForm}
            />
          </Flex>
        );
      }}
    </Formik>
  );
};
