import { ApolloError } from "@apollo/client";
import {
  Box,
  Center,
  Divider,
  Flex,
  List,
  ListItem,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import {
  FORM_SCHOOL_RANK_SUB_STATUS,
  FormSchoolRankSubStatusIndex,
} from "src/constants";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { SingleMoreMenu } from "src/scenes/orgAdmin/forms/components/SingleMoreMenu";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import * as RD from "src/types/remoteData";
import { SchoolRankWithTags } from "src/types/schoolRank";
import {
  useBoundariesMap,
  useFormAddressPosition,
} from "../Boundary/useBoundary";
import { StatusTag } from "../DataDisplay/StatusTag";
import { School } from "../Inputs/MultiSelectSchoolRank";
import { SchoolListItem } from "../Inputs/MultiSelectSchoolRank/SchoolListItem";
import { WithRequiredHasuraRoles } from "../Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "../Permissions/WithUserPermissions";
import { TagGroups } from "../Tags/TagGroups/TagGroups";
import { useGradeAnswer } from "./useGradeAnswer";

type Props = {
  formId: uuid;
  enrollmentPeriodId: uuid;
  schoolsRank: SchoolRankWithTags[];
  onRefetchSchoolsRank?: () => void;
  tagGroups?: GQL.GetSchoolsRankView_tag_group[];
  isOfferEnabled?: boolean;
  rankingEnabled: boolean;
};
export const SchoolRanking: React.FC<Props> = ({
  formId,
  enrollmentPeriodId,
  schoolsRank,
  onRefetchSchoolsRank,
  tagGroups,
  isOfferEnabled = true,
  rankingEnabled,
}) => {
  const isAdmin = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.DISTRICT_ADMIN,
    HasuraRole.ORG_ADMIN,
    HasuraRole.SCHOOL_ADMIN,
  ]);

  const addressLocationRemoteData = useFormAddressPosition({
    formId,
  }).mapError((e) => new ApolloError({ errorMessage: e.message }));
  const boundariesMapRemoteData = useBoundariesMap({ formId });
  const gradeConfigRemoteData = useGradeAnswer({ formId });

  const renderSelectItem = (school: School) => (
    <SchoolListItem
      school={school}
      addressLocation={addressLocationRemoteData}
      boundariesMap={boundariesMapRemoteData}
      gradeConfigId={gradeConfigRemoteData}
    />
  );

  return (
    <List
      styleType={rankingEnabled || isAdmin ? "none" : "disc"}
      marginLeft="0"
      display="flex"
      flexDirection="column"
      gap={3}
      fontSize="sm"
      position="relative"
    >
      {_.sortBy(schoolsRank, (schoolRank) => schoolRank.rank).map(
        (schoolRank, idx) => {
          const hasRank = rankingEnabled && typeof schoolRank.rank === "number";

          const selectedFormSchools = RD.success([
            {
              form: {
                id: schoolRank.form.id,
                status: schoolRank.form.status,
              },
              school: { id: schoolRank.school.id },
            },
          ]);

          const showDivider =
            (rankingEnabled || isAdmin) && idx < schoolsRank.length - 1;

          let ml: number;
          if (rankingEnabled) {
            ml = 2;
          } else {
            // ranking disabled
            if (isAdmin) {
              ml = 0;
            } else {
              ml = 4;
            }
          }

          return (
            <ListItem
              key={schoolRank.school.name}
              gap={3}
              alignItems="center"
              flexWrap="wrap"
              ml={ml}
              mr={2}
            >
              <VStack alignItems="initial" width="100%">
                <Flex direction="row" gap="2">
                  {hasRank && (
                    <>
                      <Box as="span">{schoolRank.rank + 1}</Box>
                      <Center height="5">
                        <Divider orientation="vertical" />
                      </Center>
                    </>
                  )}
                  {renderSelectItem(schoolRank.school)}
                  {schoolRank.sub_status && (
                    <Box minWidth="120">
                      <StatusTag
                        status={
                          FORM_SCHOOL_RANK_SUB_STATUS[
                            schoolRank.sub_status as FormSchoolRankSubStatusIndex
                          ]
                        }
                        customLabel={getSubstatusLabel(schoolRank, isAdmin)}
                      />
                    </Box>
                  )}
                  <Spacer />
                  <Box>
                    <WithRequiredHasuraRoles
                      roles={[
                        HasuraRole.ADMIN,
                        HasuraRole.DISTRICT_ADMIN,
                        HasuraRole.ORG_ADMIN,
                        HasuraRole.SCHOOL_ADMIN,
                      ]}
                    >
                      <WithUserPermissions permissions={["form:update"]}>
                        {isOfferEnabled && (
                          <SingleMoreMenu
                            selectedFormSchools={selectedFormSchools}
                            onRefetch={onRefetchSchoolsRank}
                          />
                        )}
                      </WithUserPermissions>
                    </WithRequiredHasuraRoles>
                  </Box>
                </Flex>
                <TagGroups
                  schoolRank={schoolRank}
                  tagGroups={tagGroups}
                  enrollmentPeriodId={enrollmentPeriodId}
                />
                {showDivider && <Divider orientation="horizontal" />}
              </VStack>
            </ListItem>
          );
        }
      )}
    </List>
  );
};

// This is for adding extra information to the substatus tag, including waitlist
// position and offer programs.
const getSubstatusLabel = (
  schoolRank: SchoolRankWithTags,
  isAdmin: boolean
) => {
  if (
    isAdmin &&
    schoolRank.offers[0] &&
    "grade" in schoolRank.offers[0] &&
    schoolRank.offers[0]?.grade.program
  ) {
    return `${schoolRank.offers[0]?.status}, ${schoolRank.offers[0]?.grade.program.label}`;
  }
  const waitlistPosition = schoolRank.waitlists[0]?.waitlist_position?.position;
  if (
    !schoolRank.offers[0] &&
    schoolRank.waitlists[0]?.status === GQL.waitlist_status_enum.Waitlisted &&
    waitlistPosition
  ) {
    return `${GQL.waitlist_status_enum.Waitlisted}, #${waitlistPosition}`;
  }
  return undefined;
};
